import revive_payload_client_aEi3zZV8aI from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_c7rb2pe6lwfarpfv3rrl7fnlki/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9v3jtjPA4G from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_c7rb2pe6lwfarpfv3rrl7fnlki/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_i6RPMGTn6J from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_c7rb2pe6lwfarpfv3rrl7fnlki/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_MdFVq3oKNf from "/vercel/path0/node_modules/.pnpm/@develit-io+nuxt-sentry@0.4.2_encoding@0.1.13_magicast@0.3.5_rollup@4.21.2_vue@3.5.5_typescri_z5sxm3lx3kxos3wjbsj6togl2i/node_modules/@develit-io/nuxt-sentry/dist/runtime/sentry.client.mjs";
import payload_client_umoHEHjI4o from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_c7rb2pe6lwfarpfv3rrl7fnlki/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_QOz1mfbO7I from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_c7rb2pe6lwfarpfv3rrl7fnlki/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_9WtTeE5bRp from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_c7rb2pe6lwfarpfv3rrl7fnlki/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_WqE79kSffM from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_c7rb2pe6lwfarpfv3rrl7fnlki/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_jEp5oIDcmr from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.2_typescript@5.4.5_vue@3.5.5_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_ozWeQwAHXB from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_c7rb2pe6lwfarpfv3rrl7fnlki/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _01_plugin_3EfQnkyRGh from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.15.0_magicast@0.3.5_rollup@4.21.2_vite@5.4.5_@types+node@22.2.0_terser@5.31.1__webpack-sources@3.2.3/node_modules/nuxt-i18n-micro/dist/runtime/plugins/01.plugin.js";
import _02_meta_4BHDOZaBFN from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.15.0_magicast@0.3.5_rollup@4.21.2_vite@5.4.5_@types+node@22.2.0_terser@5.31.1__webpack-sources@3.2.3/node_modules/nuxt-i18n-micro/dist/runtime/plugins/02.meta.js";
import _03_define_2cqPWLPstV from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.15.0_magicast@0.3.5_rollup@4.21.2_vite@5.4.5_@types+node@22.2.0_terser@5.31.1__webpack-sources@3.2.3/node_modules/nuxt-i18n-micro/dist/runtime/plugins/03.define.js";
export default [
  revive_payload_client_aEi3zZV8aI,
  unhead_9v3jtjPA4G,
  router_i6RPMGTn6J,
  sentry_client_MdFVq3oKNf,
  payload_client_umoHEHjI4o,
  navigation_repaint_client_QOz1mfbO7I,
  check_outdated_build_client_9WtTeE5bRp,
  chunk_reload_client_WqE79kSffM,
  plugin_vue3_jEp5oIDcmr,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ozWeQwAHXB,
  _01_plugin_3EfQnkyRGh,
  _02_meta_4BHDOZaBFN,
  _03_define_2cqPWLPstV
]